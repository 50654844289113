<template>
  <div class="w-full mb-20" v-if="!showButtons">
    <div class="grid grid-cols-2 lg:grid-cols-1 gap-4">
      <button
        @click="navigatePrevious"
        class="sm:text-base bg-promy-blue-50 px-8 py-5 rounded-lg text-promy-green-350 font-semibold hover:bg-promy-blue-100 disabled:bg-promy-gray-100 disabled:text-promy-gray-200 lg:order-2"
      >
        Précédent
      </button>
      <button
        :disabled="goNext"
        @click="navigateNext"
        class="sm:text-base bg-promy-green-350 px-11 py-5 rounded-lg text-white font-semibold hover:bg-promy-green-300 disabled:bg-promy-gray-100 disabled:text-promy-gray-200 lg:order-1"
      >
        Suivant
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    goNext: Boolean,
    form: {
      type: Object,
      required: true,
    },
  },

  methods: {
    navigateNext() {
      if (this.getStoreStepNumber === 7) {
        //submit data
        if (this.$route.params.id_revelation) {
          this.$http
            .post('grand-publics/reveler-potentiels', {
              ...this.form,
              uuid: this.$route.params.id_revelation,
            })
            .then(() => {
              this.toast(
                'Révélation de potentiel',
                'Votre demande a bien été modifiée',
                'success',
              )
              this.$router.push({
                name: this.isLoggedIn ? 'revelations' : 'adresse',
              })
            })
            .catch((error) => {
              this.displayErrorMsg(error)
            })
        } else {
          this.$http
            .post(
              'grand-publics/reveler-potentiels' +
                (this.isLoggedIn ? '' : '/guest'),
              {
                ...this.form,
              },
            )
            .then(() => {
              this.toast(
                'Révélation de potentiel',
                'La demande a été créée avec succès',
                'success',
              )
              this.$router.push({
                name: this.isLoggedIn ? 'revelations' : 'adresse',
              })
            })
            .catch((error) => {
              this.displayErrorMsg(error)
            })
        }

        return
      }
      this.$store.commit('Tunnel/SET_STEP_NUMBER', this.getStoreStepNumber + 1)
      this.changeRoute()
    },
    navigatePrevious() {
      this.$store.commit('Tunnel/SET_STEP_NUMBER', this.getStoreStepNumber - 1)
      this.changeRoute()
    },
    changeRoute() {
      this.$router.push({
        path: `/revelation/steps/${this.getStoreStepNumber}/${
          this.$route.params.id_revelation ?? ''
        }`,
        query: { id_address: this.$route.query.id_address },
      })
    },
    displayErrorMsg(errors) {
      let firstError =
        errors.response.status === 422
          ? Object.values(errors.response.data.errors)[0][0]
          : 'Une erreur est survenue'

      this.toast('Révélation de potentiel', firstError, 'warning')
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
    }),
    showButtons() {
      return this.$route.name === 'revelationStep1'
    },

    getStoreStepNumber() {
      return this.$store.getters['Tunnel/GET_STEP_NUMBER']
    },
  },
}
</script>

<style scoped>
@media (max-width: 768px) {
  .md\:bottom-x {
    bottom: 60%;
  }
}

@media (max-width: 1024px) {
  .lg\:bottom-x {
    bottom: 60%;
  }
}
</style>
